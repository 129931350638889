import React, { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';

const Navbar = ({ auth: { isAuthenticated, loading }, logout }) => {
  return (
    <Fragment>
      {/* // <!-- BEGIN: Header--> */}
      <nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav navbar-light navbar-shadow">
        <div className="navbar-container d-flex content">
          <div className="bookmark-wrapper d-flex align-items-center">
            <ul className="nav navbar-nav d-xl-none">
              <li className="nav-item">
                <Link className="nav-link menu-toggle" to="#">
                  <i className="ficon" data-feather="menu"></i>
                </Link>
              </li>
            </ul>
            <ul className="nav navbar-nav bookmark-icons">
              <li className="nav-item d-none d-lg-block">
                <Link
                  className="nav-link"
                  to="app-email.html"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Email"
                >
                  <i className="ficon" data-feather="mail"></i>
                </Link>
              </li>
              <li className="nav-item d-none d-lg-block">
                <Link
                  className="nav-link"
                  to="app-chat.html"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Chat"
                >
                  <i className="ficon" data-feather="message-square"></i>
                </Link>
              </li>
              <li className="nav-item d-none d-lg-block">
                <Link
                  className="nav-link"
                  to="app-calendar.html"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Calendar"
                >
                  <i className="ficon" data-feather="calendar"></i>
                </Link>
              </li>
              <li className="nav-item d-none d-lg-block">
                <Link
                  className="nav-link"
                  to="app-todo.html"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Todo"
                >
                  <i className="ficon" data-feather="check-square"></i>
                </Link>
              </li>
            </ul>
            <ul className="nav navbar-nav">
              <li className="nav-item d-none d-lg-block">
                <Link className="nav-link bookmark-star" to="/">
                  <i className="ficon text-warning" data-feather="star"></i>
                </Link>
                <div className="bookmark-input search-input">
                  <div className="bookmark-input-icon">
                    <i data-feather="search"></i>
                  </div>
                  <input
                    className="form-control input"
                    type="text"
                    placeholder="Bookmark"
                    tabIndex="0"
                    data-search="search"
                  />
                  <ul className="search-list search-list-bookmark"></ul>
                </div>
              </li>
            </ul>
          </div>
          <ul className="nav navbar-nav align-items-center ml-auto">
            <li className="nav-item dropdown dropdown-language">
              <Link
                className="nav-link dropdown-toggle"
                id="dropdown-flag"
                to="#"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="flag-icon flag-icon-us"></i>
                <span className="selected-language">English</span>
              </Link>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdown-flag"
              >
                <Link className="dropdown-item" to="#" data-language="en">
                  <i className="flag-icon flag-icon-us"></i> English
                </Link>
                <Link className="dropdown-item" to="#" data-language="fr">
                  <i className="flag-icon flag-icon-fr"></i> French
                </Link>
                <Link className="dropdown-item" to="#" data-language="de">
                  <i className="flag-icon flag-icon-de"></i> German
                </Link>
                <Link className="dropdown-item" to="#" data-language="pt">
                  <i className="flag-icon flag-icon-pt"></i> Portuguese
                </Link>
              </div>
            </li>
            <li className="nav-item d-none d-lg-block">
              <Link className="nav-link nav-link-style" to="">
                <i className="ficon" data-feather="moon"></i>
              </Link>
            </li>
            <li className="nav-item nav-search">
              <Link className="nav-link nav-link-search" to="">
                <i className="ficon" data-feather="search"></i>
              </Link>
              <div className="search-input">
                <div className="search-input-icon">
                  <i data-feather="search"></i>
                </div>
                <input
                  className="form-control input"
                  type="text"
                  placeholder="Explore Vuexy..."
                  tabIndex="-1"
                  data-search="search"
                />
                <div className="search-input-close">
                  <i data-feather="x"></i>
                </div>
                <ul className="search-list search-list-main"></ul>
              </div>
            </li>
            <li className="nav-item dropdown dropdown-notification mr-25">
              <Link className="nav-link" to="#" data-toggle="dropdown">
                <i className="ficon" data-feather="bell"></i>
                <span className="badge badge-pill badge-danger badge-up">
                  5
                </span>
              </Link>
            </li>
            <li className="nav-item dropdown dropdown-user">
              <Link
                className="nav-link dropdown-toggle dropdown-user-link"
                id="dropdown-user"
                to="#"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="user-nav d-sm-flex d-none">
                  <span className="user-name font-weight-bolder">John Doe</span>
                  <span className="user-status">Admin</span>
                </div>
                <span className="avatar">
                  <img
                    className="round"
                    src="../../../template/app-assets/images/portrait/small/avatar-s-11.jpg"
                    alt="avatar"
                    height="40"
                    width="40"
                  />
                  <span className="avatar-status-online"></span>
                </span>
              </Link>
              <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdown-user"
              >
                <Link className="dropdown-item" to="page-profile.html">
                  <i className="mr-50" data-feather="user"></i> Profile
                </Link>
                <Link className="dropdown-item" to="app-email.html">
                  <i className="mr-50" data-feather="mail"></i> Inbox
                </Link>
                <Link className="dropdown-item" to="app-todo.html">
                  <i className="mr-50" data-feather="check-square"></i> Task
                </Link>
                <Link className="dropdown-item" to="app-chat.html">
                  <i className="mr-50" data-feather="message-square"></i> Chats
                </Link>
                <div className="dropdown-divider"></div>
                <Link className="dropdown-item" to="page-account-settings.html">
                  <i className="mr-50" data-feather="settings"></i> Settings
                </Link>
                <Link className="dropdown-item" to="page-pricing.html">
                  <i className="mr-50" data-feather="credit-card"></i> Pricing
                </Link>
                <Link className="dropdown-item" to="page-faq.html">
                  <i className="mr-50" data-feather="help-circle"></i> FAQ
                </Link>
                <Link className="dropdown-item" onClick={logout} to="#!">
                  <i className="mr-50" data-feather="power"></i> Logout
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </nav>
      <ul className="main-search-list-defaultlist d-none">
        <li className="d-flex align-items-center">
          <Link to="#">
            <h6 className="section-label mt-75 mb-0">Files</h6>
          </Link>
        </li>
        <li className="auto-suggestion">
          <Link
            className="d-flex align-items-center justify-content-between w-100"
            to="app-file-manager.html"
          >
            <div className="d-flex">
              <div className="mr-75">
                <img
                  src="../../../template/app-assets/images/icons/xls.png"
                  alt="png"
                  height="32"
                />
              </div>
              <div className="search-data">
                <p className="search-data-title mb-0">Two new item submitted</p>
                <small className="text-muted">Marketing Manager</small>
              </div>
            </div>
            <small className="search-data-size mr-50 text-muted">
              &apos;17kb
            </small>
          </Link>
        </li>
        <li className="auto-suggestion">
          <Link
            className="d-flex align-items-center justify-content-between w-100"
            to="app-file-manager.html"
          >
            <div className="d-flex">
              <div className="mr-75">
                <img
                  src="../../../template/app-assets/images/icons/jpg.png"
                  alt="png"
                  height="32"
                />
              </div>
              <div className="search-data">
                <p className="search-data-title mb-0">52 JPG file Generated</p>
                <small className="text-muted">FontEnd Developer</small>
              </div>
            </div>
            <small className="search-data-size mr-50 text-muted">
              &apos;11kb
            </small>
          </Link>
        </li>
        <li className="auto-suggestion">
          <Link
            className="d-flex align-items-center justify-content-between w-100"
            to="app-file-manager.html"
          >
            <div className="d-flex">
              <div className="mr-75">
                <img
                  src="../../../template/app-assets/images/icons/pdf.png"
                  alt="png"
                  height="32"
                />
              </div>
              <div className="search-data">
                <p className="search-data-title mb-0">25 PDF File Uploaded</p>
                <small className="text-muted">Digital Marketing Manager</small>
              </div>
            </div>
            <small className="search-data-size mr-50 text-muted">
              &apos;150kb
            </small>
          </Link>
        </li>
        <li className="auto-suggestion">
          <Link
            className="d-flex align-items-center justify-content-between w-100"
            to="app-file-manager.html"
          >
            <div className="d-flex">
              <div className="mr-75">
                <img
                  src="../../../template/app-assets/images/icons/doc.png"
                  alt="png"
                  height="32"
                />
              </div>
              <div className="search-data">
                <p className="search-data-title mb-0">Anna_Strong.doc</p>
                <small className="text-muted">Web Designer</small>
              </div>
            </div>
            <small className="search-data-size mr-50 text-muted">
              &apos;256kb
            </small>
          </Link>
        </li>
        <li className="d-flex align-items-center">
          <Link to="#">
            <h6 className="section-label mt-75 mb-0">Members</h6>
          </Link>
        </li>
        <li className="auto-suggestion">
          <Link
            className="d-flex align-items-center justify-content-between py-50 w-100"
            to="app-user-view.html"
          >
            <div className="d-flex align-items-center">
              <div className="avatar mr-75">
                <img
                  src="../../../template/app-assets/images/portrait/small/avatar-s-8.jpg"
                  alt="png"
                  height="32"
                />
              </div>
              <div className="search-data">
                <p className="search-data-title mb-0">John Doe</p>
                <small className="text-muted">UI designer</small>
              </div>
            </div>
          </Link>
        </li>
        <li className="auto-suggestion">
          <Link
            className="d-flex align-items-center justify-content-between py-50 w-100"
            to="app-user-view.html"
          >
            <div className="d-flex align-items-center">
              <div className="avatar mr-75">
                <img
                  src="../../../template/app-assets/images/portrait/small/avatar-s-1.jpg"
                  alt="png"
                  height="32"
                />
              </div>
              <div className="search-data">
                <p className="search-data-title mb-0">Michal Clark</p>
                <small className="text-muted">FontEnd Developer</small>
              </div>
            </div>
          </Link>
        </li>
        <li className="auto-suggestion">
          <Link
            className="d-flex align-items-center justify-content-between py-50 w-100"
            to="app-user-view.html"
          >
            <div className="d-flex align-items-center">
              <div className="avatar mr-75">
                <img
                  src="../../../template/app-assets/images/portrait/small/avatar-s-14.jpg"
                  alt="png"
                  height="32"
                />
              </div>
              <div className="search-data">
                <p className="search-data-title mb-0">Milena Gibson</p>
                <small className="text-muted">Digital Marketing Manager</small>
              </div>
            </div>
          </Link>
        </li>
        <li className="auto-suggestion">
          <Link
            className="d-flex align-items-center justify-content-between py-50 w-100"
            to="app-user-view.html"
          >
            <div className="d-flex align-items-center">
              <div className="avatar mr-75">
                <img
                  src="../../../template/app-assets/images/portrait/small/avatar-s-6.jpg"
                  alt="png"
                  height="32"
                />
              </div>
              <div className="search-data">
                <p className="search-data-title mb-0">Anna Strong</p>
                <small className="text-muted">Web Designer</small>
              </div>
            </div>
          </Link>
        </li>
      </ul>
      <ul className="main-search-list-defaultlist-other-list d-none">
        <li className="auto-suggestion justify-content-between">
          <Link
            className="d-flex align-items-center justify-content-between w-100 py-50"
            to=""
          >
            <div className="d-flex justify-content-start">
              <span className="mr-75" data-feather="alert-circle"></span>
              <span>No results found.</span>
            </div>
          </Link>
        </li>
      </ul>
      {/* <!-- END: Header--> */}
      {/*  <!-- BEGIN: Main Menu--> */}
      <div
        className="main-menu menu-fixed menu-light menu-accordion menu-shadow"
        data-scroll-to-active="true"
      >
        <div className="navbar-header">
          <ul className="nav navbar-nav flex-row">
            <li className="nav-item mr-auto">
              <Link className="brand-logo" to="">
                <img
                  className="img-fluid"
                  alt="logo"
                  src="../../../tidyly-logo-dark.svg"
                />
              </Link>
            </li>
            <li className="nav-item nav-toggle">
              <Link
                className="nav-link modern-nav-toggle pr-0"
                data-toggle="collapse"
                to=""
              >
                <i
                  className="d-block d-xl-none text-primary toggle-icon font-medium-4"
                  data-feather="x"
                ></i>
                <i
                  className="d-none d-xl-block collapse-toggle-icon font-medium-4  text-primary"
                  data-feather="disc"
                  data-ticon="disc"
                ></i>
              </Link>
            </li>
          </ul>
        </div>
        <div className="shadow-bottom"></div>
        <div className="main-menu-content">
          <ul
            className="navigation navigation-main"
            id="main-menu-navigation"
            data-menu="menu-navigation"
          >
            <li className="nav-item">
              <NavLink className="d-flex align-items-center" to="/dashboard">
                <i data-feather="home"></i>
                <span
                  className="menu-title text-truncate"
                  data-i18n="Dashboard"
                >
                  Dashboard
                </span>
              </NavLink>
            </li>
            <li className=" navigation-header">
              <span data-i18n="Business Management">Business Management</span>
              <i data-feather="more-horizontal"></i>
            </li>
            <li className="nav-item">
              <NavLink className="d-flex align-items-center" to="/listings">
                <i data-feather="briefcase"></i>
                <span className="menu-title text-truncate" data-i18n="Listings">
                  Listings
                </span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="d-flex align-items-center" to="/profiles">
                <i data-feather="users"></i>
                <span className="menu-title text-truncate" data-i18n="Profiles">
                  Profiles
                </span>
              </NavLink>
            </li>
            <li className=" navigation-header">
              <span data-i18n="Admin Tools">Admin Tools</span>
              <i data-feather="more-horizontal"></i>
            </li>
            <li className=" nav-item">
              <NavLink className="d-flex align-items-center" to="/categories">
                <i data-feather="message-square"></i>
                <span
                  className="menu-title text-truncate"
                  data-i18n="categories"
                >
                  Categories
                </span>
              </NavLink>
            </li>
            <li className=" nav-item">
              <NavLink
                className="d-flex align-items-center"
                to="app-email.html"
              >
                <i data-feather="mail"></i>
                <span className="menu-title text-truncate" data-i18n="Email">
                  Email
                </span>
              </NavLink>
            </li>
            <li className=" nav-item">
              <NavLink className="d-flex align-items-center" to="app-todo.html">
                <i data-feather="check-square"></i>
                <span className="menu-title text-truncate" data-i18n="Todo">
                  Todo
                </span>
              </NavLink>
            </li>
            <li className=" nav-item">
              <NavLink
                className="d-flex align-items-center"
                to="app-calendar.html"
              >
                <i data-feather="calendar"></i>
                <span className="menu-title text-truncate" data-i18n="Calendar">
                  Calendar
                </span>
              </NavLink>
            </li>
            <li className=" nav-item">
              <NavLink className="d-flex align-items-center" to="/edit-profile">
                <i data-feather="settings"></i>
                <span
                  className="menu-title text-truncate"
                  data-i18n="Edit Profile"
                >
                  Edit Profile
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      {/* // <!-- END: Main Menu--> */}
    </Fragment>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logout })(Navbar);
