import axios from 'axios';
// import { disconnect } from 'mongoose';
import { setAlert } from './alert';

import {
  GET_LISTINGS,
  LISTINGS_ERROR,
  GET_LISTING,
  LISTING_ERROR,
  GET_REVIEWS,
  DELETE_REVIEW
} from './types';

// Get all listings
export const getListings = () => async dispatch => {
  try {
    const res = await axios.get('/api/v1/listings/');

    dispatch({
      type: GET_LISTINGS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: LISTINGS_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

// Get listing by ID
export const getListingByID = listing => async dispatch => {
  try {
    const res = await axios.get(`/api/v1/listings/${listing}`);

    dispatch({
      type: GET_LISTING,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: LISTING_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

// Edit listing by ID
export const editListing = (
  listing,
  { images = [], menu = [], featuredImage = '', ...formData },
  edit = false
) => async dispatch => {
  console.log(`I got the request ${JSON.stringify(formData)}`);

  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    const formdata = new FormData();

    for (let key in formData) {
      if (typeof formData[key] === 'object') {
        for (let subKey in formData[key]) {
          formdata.append(`${key}.${subKey}`, formData[key][subKey]);
        }
      } else {
        formdata.append(key, formData[key]);
      }
    }
    if (featuredImage) {
      formdata.append('featuredImage', featuredImage);
    }
    if (images) {
      images.forEach(image => {
        formdata.append('images', image);
      });
    }
    if (menu) {
      menu.forEach(m => {
        formdata.append('menu', m);
      });
    }

    let res;

    if (edit) {
      res = await axios.patch(`/api/v1/listings/${listing}`, formdata, config);
    } else {
      res = await axios.post(`/api/v1/listings/`, formdata);
    }

    dispatch({
      type: GET_LISTING,
      payload: res.data
    });

    dispatch(edit ? setAlert('Listing has been updated') : setAlert('Listing has been added'));
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: LISTING_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

// Get reviews listing by ID
export const getReviewsByListingID = listing => async dispatch => {
  try {
    const res = await axios.get(`/api/v1/listings/${listing}/reviews`);

    dispatch({
      type: GET_REVIEWS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: LISTING_ERROR,
      payload: { msg: error, status: error.status }
    });
  }
};

// Delete review by ID
export const deleteReviewByID = review => async dispatch => {
  try {
    const res = await axios.delete(`/api/v1/reviews/${review}`);

    dispatch({
      type: DELETE_REVIEW,
      payload: review
    });
    dispatch(setAlert('Review deleted'));
  } catch (error) {
    dispatch({
      type: LISTING_ERROR,
      payload: { msg: error, status: error.status }
    });
  }
};
