export const SET_ALERT = 'SET_ALERT';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const GET_LISTINGS = 'GET_LISTINGS';
export const LISTINGS_ERROR = 'LISTINGS_ERROR';
export const GET_LISTING = 'GET_LISTING';
export const GET_REVIEWS = 'GET_REVIEWS';
export const DELETE_REVIEW = 'DELETE_REVIEW';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const LISTING_ERROR = 'LISTING_ERROR';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const CATEGORY_ERROR = 'CATEGORY_ERROR';
export const GET_SUB_CATEGORIES = 'GET_SUB_CATEGORIES';
export const SUB_CATEGORY_ERROR = 'SUB_CATEGORY_ERROR';
export const GET_CATEGORY_DETAILS = 'GET_CATEGORY_DETAILS';
export const CATEGORY_DETAILS_ERROR = 'CATEGORY_DETAILS_ERROR';
export const GET_SUB_CATEGORY_DETAILS = 'GET_SUB_CATEGORY_DETAILS';
export const SUB_CATEGORY_DETAILS_ERROR = 'SUB_CATEGORY_DETAILS_ERROR';
