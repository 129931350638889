import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import PropTypes from 'prop-types';

const Register = ({ setAlert, register, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    passwordConfirm: ''
  });

  const { name, email, password, passwordConfirm } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      setAlert('Passwords do not match', 'danger');
    } else {
      register({ name, email, password, passwordConfirm });
    }
  };

  if (isAuthenticated) return <Redirect to="/dashboard" />;

  return (
    <Fragment>
      <div className="auth-wrapper auth-v2">
        <div className="auth-inner row m-0">
          <Link className="brand-logo">
            <img
              className="img-fluid"
              alt="logo"
              src="../../../tidyly-logo-dark.svg"
            />
          </Link>

          <div className="d-none d-lg-flex col-lg-8 align-items-center p-5">
            <div className="w-100 d-lg-flex align-items-center justify-content-center px-5">
              <img
                className="img-fluid"
                src="../../../template/app-assets/images/pages/register-v2.svg"
                alt="Register V2"
              />
            </div>
          </div>
          {/* <!-- /Left Text--> */}
          {/* <!-- Register--> */}
          <div className="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
            <div className="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
              <h4 className="card-title mb-1">
                Lets Get Started{' '}
                <span role="img" aria-labelledby="Jet">
                  🚀
                </span>
              </h4>
              <p className="card-text mb-2">
                Make your buiness stand out of the crowd!
              </p>
              <form
                className="auth-register-form mt-2"
                onSubmit={e => onSubmit(e)}
              >
                <div className="form-group">
                  <label className="form-label" forhtml="name">
                    Name
                  </label>
                  <input
                    className="form-control"
                    id="name"
                    type="text"
                    name="name"
                    value={name}
                    onChange={e => onChange(e)}
                    placeholder="John Doe"
                    aria-describedby="name"
                    autoFocus=""
                    tabIndex="1"
                  />
                </div>
                <div className="form-group">
                  <label className="form-label" forhtml="register-email">
                    Email
                  </label>
                  <input
                    className="form-control"
                    id="register-email"
                    type="text"
                    name="email"
                    value={email}
                    onChange={e => onChange(e)}
                    placeholder="john@example.com"
                    aria-describedby="register-email"
                    tabIndex="2"
                  />
                </div>
                <div className="form-group">
                  <label className="form-label" forhtml="register-password">
                    Password
                  </label>
                  <div className="input-group input-group-merge form-password-toggle">
                    <input
                      className="form-control form-control-merge"
                      id="register-password"
                      type="password"
                      name="password"
                      value={password}
                      onChange={e => onChange(e)}
                      placeholder="············"
                      aria-describedby="register-password"
                      tabIndex="3"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text cursor-pointer">
                        <i data-feather="eye"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label" forhtml="register-password">
                    Password
                  </label>
                  <div className="input-group input-group-merge form-password-toggle">
                    <input
                      className="form-control form-control-merge"
                      id="register-password"
                      type="password"
                      name="passwordConfirm"
                      value={passwordConfirm}
                      onChange={e => onChange(e)}
                      placeholder="············"
                      aria-describedby="register-password"
                      tabIndex="3"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text cursor-pointer">
                        <i data-feather="eye"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="register-privacy-policy"
                      type="checkbox"
                      tabIndex="4"
                    />
                    <label
                      className="custom-control-label"
                      forhtml="register-privacy-policy"
                    >
                      I agree to
                      <Link to="">&nbsp;privacy policy & terms</Link>
                    </label>
                  </div>
                </div>
                <button className="btn btn-primary btn-block" tabIndex="5">
                  Sign up
                </button>
              </form>
              <p className="text-center mt-2">
                <span>Already have an account?</span>
                <Link to="/login">
                  <span>&nbsp;Sign in instead</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- /Register--> */}
    </Fragment>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { setAlert, register })(Register);
