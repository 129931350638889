import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import ReviewItem from './ReviewItem';
import { getReviewsByListingID } from '../../actions/listing';

const Reviews = ({ getReviewsByListingID, listing: { reviews, listing, loading }, match }) => {
  useEffect(() => {
    // console.log(match.params.id);
    getReviewsByListingID(match.params.id);
  }, [loading, getReviewsByListingID]);

  return (
    <Fragment>
      <section id="ecommerce-products" className="list-view">
        {loading ? (
          <Spinner />
        ) : (
          <Fragment>
            {reviews?.data?.data?.length > 0 ? (
              // (console.log(`Majid ${reviews.data.data[1].reviewer.name}`),
              reviews.data.data.map(review => (
                <Fragment key={review._id}>
                  <ReviewItem review={review} listing={listing}></ReviewItem>
                </Fragment>
              ))
            ) : (
              <h4>No reviews found</h4>
            )}
          </Fragment>
        )}
      </section>
    </Fragment>
  );
  {
  }
};

Reviews.propTypes = {
  getReviewsByListingID: PropTypes.func.isRequired,
  reviews: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  review: state.review,
  listing: state.listing
});

export default connect(mapStateToProps, { getReviewsByListingID })(Reviews);
