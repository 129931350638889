import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ProfileItem = ({
  profile: {
    _id: profileId,
    name: profileName,
    email,
    photo,
    listings,
    company,
    role
  }
}) => {
  return (
    <tr>
      <td>
        <img
          src={`https://tidyly-prod.s3.ap-southeast-2.amazonaws.com/${photo}`}
          className="mr-75 rounded-circle"
          height="50"
          alt={photo}
        />
      </td>
      <td>
        <strong>{profileName}</strong>
      </td>
      <td>{email}</td>
      {/* <td>
        <Fragment>
          {badges.length > 0
            ? badges.map(badge => (
                <div className="avatar-group">
                  <div
                    data-toggle="tooltip"
                    data-popup="tooltip-custom"
                    data-placement="top"
                    title=""
                    className="avatar pull-up my-0"
                    data-original-title="Lilian Nenez"
                  >
                    <img
                      src={`https://tidyly-prod.s3.ap-southeast-2.amazonaws.com/${badge.image}`}
                      alt="Avatar"
                      height="26"
                      width="26"
                    />
                  </div>
                </div>
              ))
            : ''}
        </Fragment>
      </td> */}
      <td>
        <span className="badge badge-pill badge-light-primary mr-1">
          {role}
        </span>
      </td>
      <td>{listings.length}</td>
      <td>
        <div className="dropdown">
          <button
            type="button"
            className="btn btn-sm dropdown-toggle hide-arrow"
            data-toggle="dropdown"
          >
            <i className="fas fa-bars"></i>
          </button>
          <div className="dropdown-menu">
            <Link className="dropdown-item" to={`/profileEdit/${profileId}`}>
              <i class="far fa-edit mr-50"></i>
              <span>Edit</span>
            </Link>
            <Link className="dropdown-item">
              <i className="far fa-trash-alt mr-50"></i>
              <span>Delete</span>
            </Link>
          </div>
        </div>
      </td>
    </tr>
  );
};

ProfileItem.propTypes = {
  profile: PropTypes.object.isRequired
};

export default ProfileItem;
