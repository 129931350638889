import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { login } from '../../actions/auth';
import { setAlert } from '../../actions/alert';
import PropTypes from 'prop-types';

const Login = ({ login, isAuthenticated, setAlert }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    if (!email || !password) {
      setAlert('Please enter email and password to login', 'danger');
    } else {
      login(email, password);
    }
  };

  // Redirect if logged in
  if (isAuthenticated) return <Redirect to="/dashboard" />;

  return (
    <Fragment>
      <div className="auth-wrapper auth-v2">
        <div className="auth-inner row m-0">
          <Link className="brand-logo">
            <img
              className="img-fluid"
              alt="logo"
              src="../../../tidyly-logo-dark.svg"
            />
          </Link>
          <div className="d-none d-lg-flex col-lg-8 align-items-center p-5">
            <div className="w-100 d-lg-flex align-items-center justify-content-center px-5">
              <img
                className="img-fluid"
                src="../../../template/app-assets/images/pages/login-v2.svg"
                alt="Login V2"
              />
            </div>
          </div>
          <div className="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
            <div className="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
              <h4 className="card-title mb-1">
                Welcome to Tidyly!{' '}
                <span role="img" aria-labelledby="hand">
                  👋
                </span>
              </h4>
              <p className="card-text mb-2">
                Please sign-in to your account and start the adventure
              </p>
              <form
                className="auth-login-form mt-2"
                onSubmit={e => onSubmit(e)}
              >
                <div className="form-group">
                  <label className="form-label" forhtml="login-email">
                    Email
                  </label>
                  <input
                    className="form-control"
                    id="login-email"
                    type="text"
                    name="email"
                    placeholder="john@example.com"
                    aria-describedby="login-email"
                    autoFocus=""
                    tabIndex="1"
                    value={email}
                    onChange={e => onChange(e)}
                  />
                </div>
                <div className="form-group">
                  <div className="d-flex justify-content-between">
                    <label forhtml="login-password">Password</label>
                    <Link to="page-auth-forgot-password-v2.html">
                      <small>Forgot Password?</small>
                    </Link>
                  </div>
                  <div className="input-group input-group-merge form-password-toggle">
                    <input
                      className="form-control form-control-merge"
                      id="login-password"
                      type="password"
                      placeholder="············"
                      aria-describedby="login-password"
                      tabIndex="2"
                      name="password"
                      value={password}
                      minLength="6"
                      onChange={e => onChange(e)}
                    />
                    <div className="input-group-append">
                      <span className="input-group-text cursor-pointer">
                        <i data-feather="eye"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <button className="btn btn-primary btn-block" tabIndex="4">
                  Sign in
                </button>
              </form>
              <p className="text-center mt-2">
                <span>New on our platform?</span>
                <Link to="/register">
                  <span>&nbsp;Create an account</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Login.propTypes = {
  setAlert: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login, setAlert })(Login);
