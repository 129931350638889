import {
  GET_LISTING,
  GET_LISTINGS,
  LISTINGS_ERROR,
  LISTING_ERROR,
  GET_REVIEWS,
  DELETE_REVIEW
} from '../actions/types';

const initialState = {
  listing: null,
  listings: [],
  loading: true,
  reviews: [],
  error: {}
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_LISTINGS:
      return {
        ...state,
        listings: payload,
        loading: false
      };
    case GET_LISTING:
      return {
        ...state,
        listing: payload,
        loading: false
      };
    case GET_REVIEWS:
      // TO BE DEALT WITH ⬇️
      localStorage.setItem('page', 'ecommerce-application');
      return {
        ...state,
        reviews: payload,
        loading: false
      };
    case DELETE_REVIEW:
      return {
        ...state,
        reviews: state.reviews.filter(review => review._id !== payload),
        loading: false
      };
    case LISTINGS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case LISTING_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return {
        ...state
      };
  }
}
