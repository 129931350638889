import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { deleteReviewByID } from '../../actions/listing';
import { connect } from 'react-redux';

const ReviewItem = ({ review, deleteReviewByID }) => {
  return (
    // <!-- Review Item Starts -->
    <div className="card ecommerce-card">
      <div className="item-img text-center">
        <Link to="app-ecommerce-details.html">
          {/* {console.log(`The images: ${JSON.stringify(review.images)}`)} */}
          <img
            className="img-fluid card-img-top"
            src={
              review.images.length > 0
                ? `${review.images[0]}`
                : '../../template/app-assets/images/pages/eCommerce/1.png'
            }
            alt="img-placeholder"
          />
        </Link>
      </div>
      <div className="card-body">
        <div className="item-wrapper">
          <div className="item-rating">
            <ul className="unstyled-list list-inline">
              <li className="ratings-list-item">
                {review.rating} <i className="fas fa-star filled-star"></i>
              </li>
            </ul>
          </div>
          <div>
            <h6 className="item-price">$339.99</h6>
          </div>
        </div>
        <h6 className="item-name">
          <Link className="text-body" to={`/listingEdit/${review.listing}`}>
            {`Listing`}
          </Link>
          <span className="card-text item-company">
            By{' '}
            <Link to={`/profileEdit/${review?.reviewer?.id}`} className="company-name">
              {review?.reviewer?.name}
            </Link>
          </span>
        </h6>
        <p className="card-text item-description">{review.review}</p>
        <p className="card-text item-description">
          {review.positive.map(positive => (
            <div className="badge badge-pill badge-light-success mr-25">{positive.name}</div>
          ))}
        </p>
        <p className="card-text item-description">
          {review.negative.map(negative => (
            <div className="badge badge-pill badge-light-danger mr-25">{negative.name}</div>
          ))}
        </p>
      </div>
      <div className="item-options text-center">
        <div className="item-wrapper">
          <div className="item-cost">
            <h4 className="item-price">{review.rating}</h4>
          </div>
        </div>
        <Link to="javascript:void(0)" className="btn btn-light btn-wishlist">
          <i data-feather="heart"></i>
          <span>Make Private</span>
        </Link>
        <button onClick={e => deleteReviewByID(review._id)} className="btn btn-primary btn-cart">
          <i data-feather="shopping-cart"></i>
          <span className="add-to-cart">Delete</span>
        </button>
      </div>
    </div>
    // <!-- Review Item Ends -->
  );
};

ReviewItem.propTypes = {
  deleteReviewByID: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, { deleteReviewByID })(ReviewItem);
