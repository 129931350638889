import React, { Fragment, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { UploadProfileImage } from '../../ui/UploadProfileImage';
import { useLocation, Link } from 'react-router-dom';
import { getCategoryDetailsByID, manageCategory, getSubCategoryDetailsByID, manageSubCategory } from '../../actions/category';
import { GET_CATEGORY_DETAILS } from '../../actions/types';

const ManageCategories = ({
  getCategoryDetailsByID,
  getSubCategoryDetailsByID,
  manageCategory,
  manageSubCategory,
  category: { getCategoryDetails, getSubCategoryDetails, loading },
  history,
  match
}) => {
  const dispatch = useDispatch();

  const [categoryData, setCategoryData] = useState({
    name: '',
    image: '',
  });

  const location = useLocation();
  const pathname = location.pathname.split('/');
  const pathType = pathname.includes('subCategories');
  const subCategoriesAddPath = pathname.includes('subCategoriesAdd');
  const categoriesAddPath = pathname.includes('categoriesAdd');
  

  const categoryId = match.params.categoryId;
  const subCategoryId = match.params.id;

  useEffect(() => {

    if (!categoriesAddPath && !subCategoriesAddPath) {

      if(subCategoryId) {
        getSubCategoryDetailsByID(subCategoryId);
      } else {
        getCategoryDetailsByID(categoryId);
      }

    } else {
      dispatch({
        type: GET_CATEGORY_DETAILS,
        payload: ''
      });
    }

  }, [categoryId, subCategoryId, getCategoryDetailsByID, getSubCategoryDetailsByID]);

  useEffect(() => {

    if (pathType) {
      setCategoryData({
        name:
          loading || !getSubCategoryDetails?.data?.data?.name
            ? ''
            : getSubCategoryDetails?.data?.data?.name,
        image:
          loading || !getSubCategoryDetails?.data?.data?.image
            ? ''
            : getSubCategoryDetails?.data?.data?.image
      });
    } else {
      setCategoryData({
        name:
          loading || !getCategoryDetails?.data?.data?.name
            ? ''
            : getCategoryDetails?.data?.data?.name,
        image:
          loading || !getCategoryDetails?.data?.data?.image
            ? ''
            : getCategoryDetails?.data?.data?.image
      });
    }

  }, [loading, getCategoryDetails, getSubCategoryDetails, pathType]);

  const { name, image } = categoryData;

  const onChange = e => {
    setCategoryData({ ...categoryData, [e.target.name]: e.target.value });
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (subCategoryId || subCategoriesAddPath) {
      manageSubCategory({...categoryData, parentCategory: match.params.categoryId, subCategoryId: match.params.id },  history, !!match.params.id);
    } else {
      manageCategory({...categoryData, id: match.params.categoryId }, history, !!match.params.categoryId);
    }
  };

  const fileChangeHandler = file => {
    console.log({ file });
    if (file) {
      setCategoryData(f => ({ ...f, image: file }));
    }
  };

  const fileResetHandler = () => {
    setCategoryData(f => ({ ...f, image: '' }));
  };

  return (
    <Fragment>
      {/* <!-- account setting page --> */}
      <section id="page-account-settings">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="tab-content">
                  {/* <!-- general tab --> */}
                  <div
                    role="tabpanel"
                    className="tab-pane active"
                    id="account-vertical-general"
                    aria-labelledby="account-pill-general"
                    aria-expanded="true"
                  >
                    <form className="validate-form mt-2" onSubmit={e => onSubmit(e)}>
                      {/* <!-- header media --> */}
                      <UploadProfileImage
                        photo={image}
                        onFileChange={fileChangeHandler}
                        onReset={fileResetHandler}
                      />
                      {/* <!--/ header media --> */}

                      {/* <!-- form --> */}
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="account-name">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="account-name"
                              name="name"
                              placeholder="Name"
                              value={name}
                              onChange={e => onChange(e)}
                            />
                          </div>
                        </div>
                        {
                          subCategoriesAddPath || match.params.id ? (
                            <div className="col-12">
                              <button type="submit" className="btn btn-primary mt-2 mr-1">
                                {subCategoriesAddPath ? 'Add Sub category' : 'Save changes'}
                              </button>
                              <Link to={`/categories/${match.params.categoryId}/subCategories`}>
                                <button type="reset" className="btn btn-outline-secondary mt-2">
                                  Cancel
                                </button>
                              </Link>
                            </div>
                          ) : (
                            <div className="col-12">
                              <button type="submit" className="btn btn-primary mt-2 mr-1">
                                {match.params.categoryId ? 'Save changes' : 'Add category'}
                              </button>
                              <Link to={'/categories'}>
                                <button type="reset" className="btn btn-outline-secondary mt-2">
                                  Cancel
                                </button>
                              </Link>
                            </div>
                          )
                        }
                      </div>
                    </form>
                    {/* <!--/ form --> */}
                  </div>
                  {/* <!--/ general tab --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- / account setting page --> */}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  category: state.category
});

export default connect(mapStateToProps, {
  getCategoryDetailsByID,
  manageCategory,
  getSubCategoryDetailsByID,
  manageSubCategory
})(ManageCategories);
