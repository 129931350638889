import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ListingItem = ({
  listing: {
    _id: listingId,
    user: { _id, name },
    name: listingName,
    fullAddress,
    addressSuburb,
    addressState,
    featuredImage,
    badges,
    listingStatus
  }
}) => {
  return (
    <tr>
      <td>
        <img
          src={`https://tidyly-prod.s3.ap-southeast-2.amazonaws.com/${featuredImage}`}
          className="mr-75"
          height="50"
          alt={featuredImage}
        />
        {/* <span className="font-weight-bold">{listingName}</span> */}
        {console.log(badges.image)}
      </td>
      <td>
        <strong>{listingName}</strong>
      </td>
      <td>
        {addressSuburb}, {addressState}
      </td>
      <td>
        <Fragment>
          {badges.length > 0
            ? badges.map(badge => (
                <div className="avatar-group">
                  <div
                    data-toggle="tooltip"
                    data-popup="tooltip-custom"
                    data-placement="top"
                    title=""
                    className="avatar pull-up my-0"
                    data-original-title="Lilian Nenez"
                  >
                    <img
                      src={`https://tidyly-prod.s3.ap-southeast-2.amazonaws.com/${badge.image}`}
                      alt="Avatar"
                      height="26"
                      width="26"
                    />
                  </div>
                </div>
              ))
            : ''}
        </Fragment>
      </td>
      <td>
        <span className="badge badge-pill badge-light-primary mr-1">
          {listingStatus}
        </span>
      </td>
      <td>
        <div className="dropdown">
          <button
            type="button"
            className="btn btn-sm dropdown-toggle hide-arrow"
            data-toggle="dropdown"
          >
            <i className="fas fa-bars"></i>
          </button>
          <div className="dropdown-menu">
            <Link className="dropdown-item" to={`/listingEdit/${listingId}`}>
              <i className="far fa-edit mr-50"></i>
              <span>Edit</span>
            </Link>
            <Link
              className="dropdown-item"
              to={`/listingEdit/${listingId}/reviews`}
            >
              <i className="far fa-comment-dots mr-50"></i>
              <span>Reviews</span>
            </Link>
            <Link className="dropdown-item">
              <i className="far fa-trash-alt mr-50"></i>
              <span>Delete</span>
            </Link>
          </div>
        </div>
      </td>
    </tr>
  );
};

ListingItem.propTypes = {
  listing: PropTypes.object.isRequired
};

export default ListingItem;
