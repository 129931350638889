import { GET_CATEGORIES, GET_SUB_CATEGORIES, GET_CATEGORY_DETAILS, GET_SUB_CATEGORY_DETAILS } from "../actions/types";

const initialState = {
    category: null,
    categories: [],
    subCategories: [],
    getCategoryDetails: [],
    getSubCategoryDetails: [],
    loading: true,

    error: {}
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_CATEGORIES:
        return {
            ...state,
            categories: payload,
            loading: false
        }

        case GET_SUB_CATEGORIES:
        return {
            ...state,
            subCategories: payload,
            loading: false
        };

        case GET_CATEGORY_DETAILS:
        return {
            ...state,
            getCategoryDetails: payload,
            loading: false
        };

        case GET_SUB_CATEGORY_DETAILS:
        return {
            ...state,
            getSubCategoryDetails: payload,
            loading: false
        };

        default:
            return {
                ...state
            };
    }
}
      