import axios from 'axios';
import {
  CATEGORY_ERROR,
  GET_CATEGORIES,
  GET_SUB_CATEGORIES,
  SUB_CATEGORY_ERROR,
  GET_CATEGORY_DETAILS,
  CATEGORY_DETAILS_ERROR,
  GET_SUB_CATEGORY_DETAILS,
  SUB_CATEGORY_DETAILS_ERROR
} from './types';
import { setAlert } from './alert';

// GetCategories
export const getCategories = () => async dispatch => {
  try {
    const res = await axios.get('/api/v1/categories/');

    dispatch({
      type: GET_CATEGORIES,
      payload: res.data
    });
  } catch (error) {
    console.log(error.response);
    dispatch({
      type: CATEGORY_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

// GetSubCategories
export const getSubCategoriesByID = id => async dispatch => {
  try {
    const res = await axios.get(`/api/v1/subCategories?parentCategory=${id}`);

    dispatch({
      type: GET_SUB_CATEGORIES,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: SUB_CATEGORY_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

// GetCategoryDetails
export const getCategoryDetailsByID = id => async dispatch => {
  try {
    const res = await axios.get(`/api/v1/categories/${id}`);

    dispatch({
      type: GET_CATEGORY_DETAILS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: CATEGORY_DETAILS_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

// ManageCategory
export const manageCategory = (
  { id, ...categoryData },
  history,
  edit = false
) => async dispatch => {
  console.log(`I got the request for add/edit category ${JSON.stringify(categoryData)}`);

  try {
    const formdata = new FormData();
    Object.keys(categoryData).map(k => formdata.append(k, categoryData[k]));

    let res;

    if (edit) {
      res = await axios.patch(`/api/v1/categories/${id}`, formdata);
      history.push('/categories');
    } else {
      res = await axios.post(`/api/v1/categories`, formdata);
      history.push('/categories');
    }

    dispatch({
      type: GET_CATEGORY_DETAILS,
      payload: res.data
    });

    dispatch(
      setAlert(edit ? 'Category Updated Successfully ✅ ' : 'Category Added Successfully ✅')
    );
  } catch (error) {
    dispatch({
      type: CATEGORY_DETAILS_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

// getSubCategoriesDetailsByID
export const getSubCategoryDetailsByID = subCategoryId => async dispatch => {
  try {
    const res = await axios.get(`/api/v1/subCategories/${subCategoryId}`);

    dispatch({
      type: GET_SUB_CATEGORY_DETAILS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: SUB_CATEGORY_DETAILS_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

// ManageSubCategory
export const manageSubCategory = (
  { subCategoryId, ...categoryData },
  history,
  edit = false
) => async dispatch => {
  console.log(`I got the request for add/edit category ${JSON.stringify(categoryData)}`);

  try {
    let res;

    const formdata = new FormData();
    Object.keys(categoryData).map(k => formdata.append(k, categoryData[k]));

    if (edit) {
      res = await axios.patch(`/api/v1/subCategories/${subCategoryId}`, formdata);
      history.push(`/categories/${categoryData.parentCategory}/subCategories`);
    } else {
      res = await axios.post(`/api/v1/subCategories`, formdata);
      history.push(`/categories/${categoryData.parentCategory}/subCategories`);
    }

    dispatch({
      type: GET_SUB_CATEGORY_DETAILS,
      payload: res.data
    });

    dispatch(
      setAlert(edit ? 'Sub Category Updated Successfully ✅' : 'Sub Category Added Successfully ✅')
    );
  } catch (error) {
    dispatch({
      type: SUB_CATEGORY_DETAILS_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};
