import axios from 'axios';
export const API = {
    getAllCategories: async () => {
        return axios.get('/api/v1/categories');
    },
    getSubCategories: async (id) => {
        return axios.get(`/api/v1/subCategories?parentCategory=${id}`);
    },
    getAssociatedUser: async () => {
        return axios.get(`/api/v1/users`);
    }
}