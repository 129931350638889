import React, { Fragment, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProfileById, editProfileById } from '../../actions/profile';
import { UploadProfileImage } from '../../ui/UploadProfileImage';

const EditProfile = ({
  getProfileById,
  editProfileById,
  profile: { profile, loading },
  history,
  match
}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    photo: '',
    company: ''
  });

  const location = useLocation();
  const pathname = location.pathname.split('/');
  const pathType = pathname.includes('profileAdd');

  useEffect(() => {
    if (!pathType) {
      getProfileById(match.params.id);
    }
  }, [match.params.id])

  useEffect(() => {
    setFormData({
      name: loading || !profile?.data?.data?.name ? '' : profile?.data?.data?.name,
      photo: loading || !profile?.data?.data?.photo ? '' : profile?.data?.data?.photo,
      email: loading || !profile?.data?.data?.email ? '' : profile?.data?.data?.email,
      phone: loading || !profile?.data?.data?.phone ? '' : profile?.data?.data?.phone,
      company:
        loading || !profile?.data?.data?.company ? '' : profile?.data?.data?.company
    });
  }, [loading, getProfileById, profile]);

  const { name, email, phone, photo, company } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    editProfileById(match.params.id, formData, history, !!match.params.id);
  };

  const fileChangeHandler = file => {
    console.log({ file });
    if (file) {
      setFormData(f => ({ ...f, photo: file }));
    }
  };

  const fileResetHandler = () => {
    setFormData(f => ({ ...f, photo: '' }));
  };

  return (
    <Fragment>
      {/* <!-- account setting page --> */}
      <section id="page-account-settings">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="tab-content">
                  {/* <!-- general tab --> */}
                  <div
                    role="tabpanel"
                    className="tab-pane active"
                    id="account-vertical-general"
                    aria-labelledby="account-pill-general"
                    aria-expanded="true"
                  >
                    <form
                      className="validate-form mt-2"
                      onSubmit={e => onSubmit(e)}
                    >
                      {/* <!-- header media --> */}
                      <UploadProfileImage
                        photo={photo}
                        onFileChange={fileChangeHandler}
                        onReset={fileResetHandler}
                      />
                      {/* <!--/ header media --> */}

                      {/* <!-- form --> */}
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="account-name">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="account-name"
                              name="name"
                              placeholder="Full Name"
                              value={name}
                              onChange={e => onChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="account-e-mail">E-mail</label>
                            <input
                              type="email"
                              className="form-control"
                              id="account-e-mail"
                              name="email"
                              placeholder="Email"
                              value={email}
                              onChange={e => onChange(e)}
                              disabled = {match.params.id}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="company-name">Company Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="company-name"
                              name="company"
                              placeholder="Company name"
                              value={company}
                              onChange={e => onChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="mobile-number">Mobile Number</label>
                            <input
                              type="number"
                              className="form-control"
                              id="phone-number"
                              name="phone"
                              placeholder="Mobile Number"
                              value={phone}
                              onChange={e => onChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-12 mt-75">
                          {/* <div
                            className="alert alert-warning mb-50"
                            role="alert"
                          >
                            {loading || profile.data.data.verifiedEmail
                              ? ''
                              : verifyEmailAlert()}
                          </div> */}
                        </div>
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary mt-2 mr-1"
                          >
                            {match.params.id ? 'Save changes' : 'Add profile'}
                          </button>
                          <button
                            type="reset"
                            className="btn btn-outline-secondary mt-2"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                    {/* <!--/ form --> */}
                  </div>
                  {/* <!--/ general tab --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- / account setting page --> */}
    </Fragment>
  );
};

EditProfile.propTypes = {
  editProfileById: PropTypes.func.isRequired,
  getProfileById: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile
});

export default connect(mapStateToProps, { getProfileById, editProfileById })(
  EditProfile
);
