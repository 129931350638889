import React, { useState } from 'react';
import { Fragment } from 'react';

const UploadProfileImage = ({ photo, onFileChange, onReset }) => {
  const [imageSrc, setImageSrc] = useState();

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      cb(reader.result);
    };
    reader.onerror = function(error) {
      console.log('Error: ', error);
    };
  };

  const onFileChangeHandler = e => {
    const file = e.target.files[0];
    if (file) {
      getBase64(file, base64 => setImageSrc(base64));
      onFileChange(file);
    }
  };

  const onFileResetHandler = () => {
    setImageSrc();
    onReset();
  };

  return (
    <div className="d-flex">
      <div className="media">
        <img
          src={
            imageSrc
              ? imageSrc
              : photo
              ? `https://tidyly-dev.s3-ap-southeast-2.amazonaws.com/${photo}`
              : '../../../template/app-assets/images/portrait/small/avatar-s-11.jpg'
          }
          id="account-upload-img"
          className="rounded mr-50"
          alt={photo}
          height="80"
          width="80"
        />
      </div>
      <div className="media-body mt-75 ml-1">
        <label
          htmlFor="account-upload"
          className="btn btn-sm btn-primary mb-75 mr-75"
        >
          Upload
        </label>
        <input
          type="file"
          id="account-upload"
          hidden
          accept="image/*"
          onChange={onFileChangeHandler}
        />
        <button
          className="btn btn-sm btn-outline-secondary mb-75"
          onClick={onFileResetHandler}
          type="button"
        >
          Reset
        </button>
        <p>Allowed JPG, GIF or PNG. Max size of 800kB</p>
      </div>
    </div>
  );
};

export default UploadProfileImage;
