import React from 'react';
import { Link } from 'react-router-dom';

const CategoriesItem = ({
    category: {
        _id: categoryId,
        name,
        image
    },
    subCategoriesItem,
    pathname
}) => {
    return (
        <tr>
            <td>
                <img
                    src={`https://tidyly-prod.s3.ap-southeast-2.amazonaws.com/${image}`}
                    className="mr-75"
                    height="50"
                    alt="image"
                />
            </td>
            <td>
                <strong>{name}</strong>
            </td>
            <td>
                <div className="dropdown">
                    <button
                        type="button"
                        className="btn btn-sm dropdown-toggle hide-arrow"
                        data-toggle="dropdown"
                    >
                        <i className="fas fa-bars"></i>
                    </button>
                    <div className="dropdown-menu">
                        <Link className="dropdown-item" to={`${pathname}/${categoryId}`}>
                            <i className="far fa-edit mr-50"></i>
                            <span>Edit</span>
                        </Link>
                        {
                            subCategoriesItem && (
                                <Link
                                    className="dropdown-item"
                                    to={`/categories/${categoryId}/subCategories`}
                                >
                                    <i className="fas fa-sitemap mr-50" />
                                    <span>Sub Categories</span>
                                </Link>
                            )
                        }
                    </div>
                </div>
            </td>
        </tr>
    )
}

export default CategoriesItem;
