import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';

const LocationSearchInput = ({ onChange, value, ...props }) => {
  const [state, setState] = useState({
    address: value
  });

  useEffect(() => {
    setState(s => ({ ...s, address: value }));
  }, [value]);

  const handleChange = address => {
    setState(s => ({ ...s, address }));
  };

  const handleSelect = (address, placeId) => {
    setState(s => ({ ...s, address }));
    geocodeByPlaceId(placeId).then(async results =>
      onChange({ ...results[0], ...(await getLatLng(results[0])) })
    );
  };
  return (
    <PlacesAutocomplete value={state.address} onChange={handleChange} onSelect={handleSelect}>
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: 'Search Places ...',
              className: 'location-search-input',
              ...props
            })}
          />
          <div
            className="autocomplete-dropdown-container w-100"
            style={{
              zIndex: 1,
              position: 'absolute',
              boxShadow: '5px 5px 6px #ccc'
            }}
          >
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, idx) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {
                    backgroundColor: '#fafafa',
                    cursor: 'pointer',
                    padding: '5px 10px'
                  }
                : {
                    backgroundColor: '#ffffff',
                    cursor: 'pointer',
                    padding: '5px 10px'
                  };
              return (
                <div
                  key={idx}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style
                  })}
                >
                  <span key={suggestion.id}>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

LocationSearchInput.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default LocationSearchInput;
