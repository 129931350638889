import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Spinner from '../../components/layout/Spinner';
import { getListingByID, editListing } from '../../actions/listing';
import { UploadProfileImage } from '../../ui/UploadProfileImage';
import LocationSearchInput from '../../ui/LocationSearchInput/LocationSearchInput';
import DropZone from '../../ui/DropZone/DropZone';
import { getParsedAddress } from '../../utils/fn';
import { GET_LISTING } from '../../actions/types';
import { API } from '../../http/api';

const EditListing = ({ getListingByID, editListing, listing: { listing, loading }, match }) => {

  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: '',
    address: '',
    subCategory: '',
    parentCategory: '',
    summary: '',
    description: '',
    phone: '',
    company: '',
    user: '',
    ratingsAverage: '',
    priceCategory: '',
    images: '',
    menu: ''
  });

  const [parentCategoriesData, setParentCategoriesData] = useState([]);
  const [subCategoriesData, setSubCategoriesData] = useState([]);
  const [associatedUserData, setAssociatedUserData] = useState([]);
  

  const location = useLocation();
  const pathname = location.pathname.split('/');
  const pathType = pathname.includes('listingsAdd');


  useEffect(() => {
    if (!pathType) {
      getListingByID(match.params.id);
    } else {
      dispatch({
        type: GET_LISTING,
        payload: ''
      });
    }
  }, [match.params.id]);

  useEffect(() => {
    setFormData({
      name: loading || !listing?.data?.data?.name ? '' : listing?.data?.data?.name,
      address: loading || !listing?.data?.data?.fullAddress ? '' : listing?.data?.data?.fullAddress,
      parentCategory:
        loading || !listing?.data?.data?.subCategory.parentCategory
          ? ''
          : listing?.data?.data?.subCategory.parentCategory,
      subCategory:
        loading || !listing?.data?.data?.subCategory._id
          ? ''
          : listing?.data?.data?.subCategory._id,
      summary: loading || !listing?.data?.data?.summary ? '' : listing?.data?.data?.summary,
      featuredImage:
        loading || !listing?.data?.data?.featuredImage ? '' : listing?.data?.data?.featuredImage,
      description:
        loading || !listing?.data?.data?.description ? '' : listing?.data?.data?.description,
      company: loading || !listing?.data?.data?.company ? '' : listing?.data?.data?.company,
      user: loading || !listing?.data?.data?.user?._id ? '' : listing?.data?.data?.user?._id,
      ratingsAverage:
        loading || !listing?.data?.data?.ratingsAverage ? '' : listing?.data?.data?.ratingsAverage,
      priceCategory:
        loading || !listing?.data?.data?.priceCategory ? '' : listing?.data?.data?.priceCategory,
      images: loading || !listing?.data?.data?.images ? [] : listing?.data?.data?.images,
      menu: loading || !listing?.data?.data?.menu ? [] : listing?.data?.data?.menu,
    });
  }, [loading, getListingByID, listing]);

  useEffect(() => {
    API.getAllCategories().then(
      response => {
        setParentCategoriesData(response?.data?.data?.data);
      },
      error => {
        console.log(error);
      }
    );
  }, []);
  
  useEffect(() => {
    if (formData.parentCategory) {
      API.getSubCategories(formData.parentCategory).then(
        response => {
          setSubCategoriesData(response?.data?.data?.data);
        },
        error => {
          console.log(error);
        }
        );
      }
    }, [formData.parentCategory]);


  useEffect(() => {
    API.getAssociatedUser().then(
      response => {
        setAssociatedUserData(response?.data?.data?.data);
      },
      error => {
        console.log(error);
      }
    );
  }, []);

  const {
    name,
    address,
    subCategory,
    parentCategory,
    summary,
    user,
    description,
    ratingsAverage,
    priceCategory,
    featuredImage,
  } = formData;


  const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    console.log(`I tried to submit ${formData}`);
    editListing(match.params.id, formData, !!match.params.id);
  };

  const fileChangeHandler = file => {
    if (file) {
      setFormData(f => ({ ...f, featuredImage: file }));
    }
  };

  const fileResetHandler = () => {
    setFormData(f => ({
      ...f,
      featuredImage:
        loading || !listing?.data?.data?.featuredImage ? '' : listing?.data?.data?.featuredImage
    }));
  };

  const addressChangeHandler = address => {
    const { address_components, formatted_address, lat, lng } = address;
    const { country, suburb, state } = getParsedAddress(address_components);
    const addr = {
      addressCountry: country,
      fullAddress: formatted_address,
      addressSuburb: suburb,
      addressState: state,
      listingLocation: {
        type: 'Point',
        coordinates: [lng, lat],
        address: formatted_address
      }
    };
    setFormData(f => ({ ...f, ...addr }));
  };

  const dropZoneFileChangeHandler = (files, name) => {
    setFormData(f => ({ ...f, [name]: files }));
  };

  return (
    <Fragment>
      {listing == null || loading ? (
        Spinner
      ) : (
        <Fragment>
          {/* <!-- account setting page --> */}
          <section id="page-account-settings">
            <div className="row">
              {/* <!-- right content section --> */}
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="tab-content">
                      {/* <!-- general tab --> */}
                      <div
                        role="tabpanel"
                        className="tab-pane active"
                        id="account-vertical-general"
                        aria-labelledby="account-pill-general"
                        aria-expanded="true"
                      >
                        {/* <!-- header media --> */}
                        <UploadProfileImage
                          photo={featuredImage}
                          onFileChange={fileChangeHandler}
                          onReset={fileResetHandler}
                        />
                        {/* <!--/ header media --> */}

                        {/* <!-- form --> */}
                        <form className="validate-form mt-2" onSubmit={e => onSubmit(e)}>
                          <div className="row">
                            <div className="col-12 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="account-name">Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="account-name"
                                  name="name"
                                  placeholder="Full Name"
                                  value={name}
                                  onChange={e => onChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="business-address">Full Address</label>
                                <LocationSearchInput
                                  type="address"
                                  className="form-control"
                                  id="business-address"
                                  name="address"
                                  placeholder="address"
                                  value={address}
                                  onChange={addressChangeHandler}
                                />
                                {/* <input
                                  onChange={e => onChange(e)}
                                /> */}
                              </div>
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="parent-category">Category</label>
                                <select
                                  className="form-control"
                                  aria-label="Default select example"
                                  id="parent-category"
                                  name="parentCategory"
                                  placeholder="Category"
                                  value={formData.parentCategory}
                                  onChange={e => onChange(e)}
                                >
                                  <option>Open this select menu</option>
                                  {parentCategoriesData.map(val => (
                                    <option key={val.id} value={val.id}>
                                      {val.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="parent-category">Subcategory Name</label>
                                <select
                                  className="form-control"
                                  aria-label="Default select example"
                                  id="subcategory-name"
                                  name="subCategory"
                                  placeholder="Subcategory name"
                                  value={formData.subCategory}
                                  onChange={e => onChange(e)}
                                >
                                  <option>Open this select menu</option>
                                  {subCategoriesData.map(val => (
                                    <option key={val._id} value={val._id}>
                                      {val.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="summary">Summary</label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  id="summary"
                                  name="summary"
                                  placeholder="Business summary goes here"
                                  value={summary}
                                  onChange={e => onChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  id="description"
                                  name="description"
                                  placeholder="Business description goes here"
                                  value={description}
                                  onChange={e => onChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="parent-category">Associated User</label>
                                <select
                                  className="form-control"
                                  aria-label="Default select example"
                                  id="user"
                                  name="user"
                                  value={formData.user}
                                  onChange={e => onChange(e)}
                                >
                                  <option>Open this select menu</option>
                                  {associatedUserData.map(val => (
                                    <option
                                      key={val.id}
                                      value={val.id}
                                    >{`${val.name} (${val.email})`}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="rating-score">Rating Score</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="rating-score"
                                  name="ratingsAverage"
                                  placeholder="Reviews Rating Score"
                                  min="1"
                                  max="5"
                                  step=".01"
                                  value={ratingsAverage}
                                  onChange={e => onChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="price-category">Price Category</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="price-category"
                                  name="priceCategory"
                                  placeholder="Price category"
                                  min="1"
                                  max="5"
                                  value={priceCategory}
                                  onChange={e => onChange(e)}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="form-group">
                                <label htmlFor="price-category">Price Category</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="price-category"
                                  name="priceCategory"
                                  placeholder="Price category"
                                  min="1"
                                  max="5"
                                  value={priceCategory}
                                  onChange={e => onChange(e)}
                                />
                              </div>
                            </div>

                            {/* <!-- remove thumbnail file upload starts --> */}
                            <div className="col-12 col-sm-6">
                              <div className="card">
                                <div className="card-header p-0 pt-1">
                                  <h4 className="card-title">Business Images</h4>
                                </div>
                                <div className="card-body p-0">
                                  <p className="card-text">Please upload all your images here</p>
                                  <DropZone
                                    onChangeFiles={files =>
                                      dropZoneFileChangeHandler(files, 'images')
                                    }
                                    images = {listing?.data?.data?.images || []}
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <!-- remove thumbnail file upload ends --> */}

                            {/* <!-- remove thumbnail file upload starts --> */}
                            <div className="col-12 col-sm-6">
                              <div className="card">
                                <div className="card-header p-0 pt-1">
                                  <h4 className="card-title">Menu</h4>
                                </div>
                                <div className="card-body p-0">
                                  <p className="card-text">
                                    Please upload all your menu or pricelist here
                                  </p>
                                  <DropZone
                                  images={listing?.data?.data?.menu || []}
                                    onChangeFiles={files =>
                                      dropZoneFileChangeHandler(files, 'menu')
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <!-- remove thumbnail file upload ends --> */}

                            <div className="col-12">
                              <button type="submit" className="btn btn-primary mt-2 mr-1">
                                {match.params.id ? 'Save changes' : 'Add listing'}
                              </button>
                              <Link to="/listings" className="btn btn-outline-secondary mt-2">
                                Cancel
                              </Link>
                            </div>
                          </div>
                        </form>
                        {/* <!--/ form --> */}
                      </div>
                      {/* <!--/ general tab --> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--/ right content section --> */}
            </div>
          </section>
          {/* <!-- / account setting page --> */}
        </Fragment>
      )}
    </Fragment>
  );
};

EditListing.propTypes = {
  getListingByID: PropTypes.func.isRequired,
  editListing: PropTypes.func.isRequired,
  listing: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  listing: state.listing
});

export default connect(mapStateToProps, { editListing, getListingByID })(EditListing);
