import React, { Fragment, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createProfile, getCurrentProfile } from '../../actions/profile';
import { UploadProfileImage } from '../../ui/UploadProfileImage';

const EditMyProfile = ({
  profile: { profile, loading },
  createProfile,
  getCurrentProfile,
  history
}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    photo: '',
    company: ''
  });

  useEffect(() => {
    getCurrentProfile();

    setFormData({
      name: loading || !profile?.data?.data?.name ? '' : profile?.data?.data?.name,
      email: loading || !profile?.data?.data?.email ? '' : profile?.data?.data?.email,
      phone: loading || !profile?.data?.data?.phone ? '' : profile?.data?.data?.phone,
      photo: loading || !profile?.data?.data?.photo ? '' : profile?.data?.data?.photo,
      company:
        loading || !profile?.data?.data?.company ? '' : profile?.data?.data?.company
    });
  }, [loading, getCurrentProfile]);
  const { name, email, phone, photo, company } = formData;

  // const verifyEmailAlert = () => (
  //   <h4 className="alert-heading">
  //     {' '}
  //     Your email is not confirmed. Please check your inbox.
  //   </h4>
  // );

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    createProfile(formData, history, true);
  };

  const fileChangeHandler = file => {
    console.log({ file });
    if (file) {
      setFormData(f => ({ ...f, photo: file }));
    }
  };

  const fileResetHandler = () => {
    setFormData(f => ({
      ...f,
      photo: loading || !profile.data.data.photo ? '' : profile.data.data.photo
    }));
  };

  return (
    <Fragment>
      {/* <!-- account setting page --> */}
      <section id="page-account-settings">
        <div className="row">
          {/* <!-- left menu section --> */}
          <div className="col-md-3 mb-2 mb-md-0">
            <ul className="nav nav-pills flex-column nav-left">
              {/* <!-- general --> */}
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  id="account-pill-general"
                  data-toggle="pill"
                  to="#account-vertical-general"
                  aria-expanded="true"
                >
                  <i data-feather="user" className="font-medium-3 mr-1"></i>
                  <span className="font-weight-bold">General</span>
                </Link>
              </li>
              {/* <!-- change password --> */}
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="account-pill-password"
                  data-toggle="pill"
                  href="#account-vertical-password"
                  aria-expanded="false"
                >
                  <i data-feather="lock" className="font-medium-3 mr-1"></i>
                  <span className="font-weight-bold">Change Password</span>
                </a>
              </li>
            </ul>
          </div>
          {/* <!--/ left menu section --> */}

          {/* <!-- right content section --> */}
          <div className="col-md-9">
            <div className="card">
              <div className="card-body">
                <div className="tab-content">
                  {/* <!-- general tab --> */}
                  <div
                    role="tabpanel"
                    className="tab-pane active"
                    id="account-vertical-general"
                    aria-labelledby="account-pill-general"
                    aria-expanded="true"
                  >
                    {/* <!-- header media --> */}
                    <UploadProfileImage
                      photo={photo}
                      onFileChange={fileChangeHandler}
                      onReset={fileResetHandler}
                    />
                    {/* <!--/ header media --> */}

                    {/* <!-- form --> */}
                    <form
                      className="validate-form mt-2"
                      onSubmit={e => onSubmit(e)}
                    >
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="account-name">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="account-name"
                              name="name"
                              placeholder="Full Name"
                              value={name}
                              onChange={e => onChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="account-e-mail">E-mail</label>
                            <input
                              type="email"
                              className="form-control"
                              id="account-e-mail"
                              name="email"
                              placeholder="Email"
                              value={email}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="company-name">Company Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="company-name"
                              name="company"
                              placeholder="Company name"
                              value={company}
                              onChange={e => onChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="mobile-number">Mobile Number</label>
                            <input
                              type="number"
                              className="form-control"
                              id="phone-number"
                              name="phone"
                              placeholder="Mobile Number"
                              value={phone}
                              onChange={e => onChange(e)}
                            />
                          </div>
                        </div>
                        <div className="col-12 mt-75">
                          {/* <div
                            className="alert alert-warning mb-50"
                            role="alert"
                          >
                            {loading || profile.data.data.verifiedEmail
                              ? ''
                              : verifyEmailAlert()}
                          </div> */}
                        </div>
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary mt-2 mr-1"
                          >
                            Save changes
                          </button>
                          <button
                            type="reset"
                            className="btn btn-outline-secondary mt-2"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                    {/* <!--/ form --> */}
                  </div>
                  {/* <!--/ general tab --> */}

                  {/* <!-- change password --> */}
                  <div
                    className="tab-pane fade"
                    id="account-vertical-password"
                    role="tabpanel"
                    aria-labelledby="account-pill-password"
                    aria-expanded="false"
                  >
                    {/* <!-- form --> */}
                    <form className="validate-form">
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="account-old-password">
                              Old Password
                            </label>
                            <div className="input-group form-password-toggle input-group-merge">
                              <input
                                type="password"
                                className="form-control"
                                id="account-old-password"
                                name="password"
                                placeholder="Old Password"
                              />
                              <div className="input-group-append">
                                <div className="input-group-text cursor-pointer">
                                  <i data-feather="eye"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="account-new-password">
                              New Password
                            </label>
                            <div className="input-group form-password-toggle input-group-merge">
                              <input
                                type="password"
                                id="account-new-password"
                                name="new-password"
                                className="form-control"
                                placeholder="New Password"
                              />
                              <div className="input-group-append">
                                <div className="input-group-text cursor-pointer">
                                  <i data-feather="eye"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6">
                          <div className="form-group">
                            <label htmlFor="account-retype-new-password">
                              Retype New Password
                            </label>
                            <div className="input-group form-password-toggle input-group-merge">
                              <input
                                type="password"
                                className="form-control"
                                id="account-retype-new-password"
                                name="confirm-new-password"
                                placeholder="New Password"
                              />
                              <div className="input-group-append">
                                <div className="input-group-text cursor-pointer">
                                  <i data-feather="eye"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <button
                            type="submit"
                            className="btn btn-primary mr-1 mt-1"
                          >
                            Save changes
                          </button>
                          <button
                            type="reset"
                            className="btn btn-outline-secondary mt-1"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                    {/* <!--/ form --> */}
                  </div>
                  {/* <!--/ change password --> */}
                </div>
              </div>
            </div>
          </div>
          {/* <!--/ right content section --> */}
        </div>
      </section>
      {/* <!-- / account setting page --> */}
    </Fragment>
  );
};

EditMyProfile.propTypes = {
  createProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile
});

export default connect(mapStateToProps, { createProfile, getCurrentProfile })(
  EditMyProfile
);
