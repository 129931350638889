import axios from 'axios';
// import { disconnect } from 'mongoose';
import { setAlert } from './alert';

import {
  CLEAR_PROFILE,
  GET_PROFILE,
  GET_PROFILES,
  PROFILE_ERROR
} from './types';

// Get the current users profile
export const getCurrentProfile = () => async dispatch => {
  try {
    const res = await axios.get('/api/v1/users/me');

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

// Get user profile by ID
export const getProfileById = listing => async dispatch => {
  try {
    const res = await axios.get(`/api/v1/users/${listing}`);

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

// Edit user profile by ID
export const editProfileById = (
  userId,
  formData,
  history,
  edit = false
) => async dispatch => {
  console.log(`I got the request for user update ${JSON.stringify(formData)}`);

  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const formdata = new FormData();
    Object.keys(formData).map((k) =>
      formdata.append(k, formData[k])
    );

    let res;

    if (edit) {
      res = await axios.patch(`/api/v1/users/${userId}`, formdata, config);
    } else {
      res = await axios.post(`/api/v1/users/`, formdata);
    }


    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });

    dispatch( edit ? setAlert('Profile Updated Successfully ✅') : setAlert('Profile Added Successfully ✅'));

  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

// Get all profiles
export const getProfiles = () => async dispatch => {
  // dispatch({ type: CLEAR_PROFILE });

  try {
    const res = await axios.get('/api/v1/users/');

    dispatch({
      type: GET_PROFILES,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};

// Create or update my profile
export const createProfile = (
  formData,
  history,
  edit = false
) => async dispatch => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const formdata = new FormData();
    Object.keys(formData).map((k) =>
      formdata.append(k, formData[k])
    );

    const res = await axios.patch('/api/v1/users/updateMe', formdata, config);

    dispatch({
      type: GET_PROFILE,
      payload: res.data
    });

    dispatch(
      setAlert(edit ? 'Profile Updated Successfully ✅' : 'Profile Created ✅')
    );

    if (!edit) {
      history.push('/dashboard');
    }
  } catch (error) {
    const errors = error.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: error.response.statusText, status: error.response.status }
    });
  }
};
