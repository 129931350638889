import React, { Fragment, useEffect } from 'react';
import Spinner from '../layout/Spinner';
import CategoriesItem from './CategoriesItem';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { getSubCategoriesByID } from '../../actions/category';

const SubCategories = ({ getSubCategoriesByID, category: { subCategories, loading }, match }) => {
  useEffect(() => {
    getSubCategoriesByID(match.params.categoryId);
  }, [match.params.categoryId, getSubCategoriesByID, loading]);

  const location = useLocation();
  const pathname = location.pathname;

  return (
    <Fragment>
      <div className="row" id="basic-table">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">{subCategories?.data?.data[0]?.parentCategory?.name}</h4>

              <Link to={`/subCategoriesAdd/${match.params.categoryId}`}>
                <button type="submit" className="btn btn-primary">
                  Add sub category
                </button>
              </Link>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="4">
                        <Spinner />
                      </td>
                    </tr>
                  ) : (
                    <Fragment>
                      {subCategories?.data?.data?.length > 0 ? (
                        subCategories?.data?.data.map(category => (
                          <Fragment key={category._id}>
                            <CategoriesItem
                              category={category}
                              subCategoriesItem={false}
                              pathname={pathname}
                            />
                          </Fragment>
                        ))
                      ) : (
                        <tr colSpan={3}>
                          <td>
                            <h4>No Sub Categories found</h4>
                          </td>
                        </tr>
                      )}
                    </Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  category: state.category
});

export default connect(mapStateToProps, { getSubCategoriesByID })(SubCategories);
