import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import ProfileItem from './ProfileItem';
import { getProfiles } from '../../actions/profile';
// import { Link } from 'react-router-dom';

const Profiles = ({ getProfiles, profile: { profiles, loading } }) => {
  useEffect(() => {
    getProfiles();
  }, [loading, getProfiles]);

  return (
    <Fragment>
      <div className="row" id="basic-table">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Profiles</h4>
              {/* <Link to={`/profileAdd`}>
                  <button
                      type="submit"
                      className="btn btn-primary"
                  >
                      Add profile
                  </button>
              </Link> */}
            </div>

            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>User Name</th>
                    <th>Email Address</th>
                    <th>User Role</th>
                    <th>Listings</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="4">
                        <Spinner />
                      </td>
                    </tr>
                  ) : (
                    <Fragment>
                      {profiles.data.data.length > 0 ? (
                        profiles.data.data.map(profile => (
                          <Fragment key={profile._id}>
                            <ProfileItem profile={profile}></ProfileItem>
                          </Fragment>
                        ))
                      ) : (
                        <h4>No profile found</h4>
                      )}
                    </Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Profiles.propTypes = {
  getProfiles: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile
});

export default connect(mapStateToProps, { getProfiles })(Profiles);
