import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import ListingItem from './ListingItem';
import { getListings } from '../../actions/listing';
import { Link } from 'react-router-dom';

const Listings = ({ getListings, listing: { listings, loading } }) => {
  useEffect(() => {
    getListings();
  }, [loading, getListings]);

  return (
    <Fragment>
      <div className="row" id="basic-table">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Listings</h4>
              <Link to={`/listingsAdd`}>
                  <button
                      type="submit"
                      className="btn btn-primary"
                  >
                      Add listing
                  </button>
              </Link>
            </div>

            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Business Name</th>
                    <th>Address</th>
                    <th>Badges</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="4">
                        <Spinner />
                      </td>
                    </tr>
                  ) : (
                    <Fragment>
                      {listings?.data?.data?.length > 0 ? (
                        listings.data.data.map(listing => (
                          <Fragment key={listing._id}>
                            <ListingItem listing={listing}></ListingItem>
                          </Fragment>
                        ))
                      ) : (
                        <h4>No listing found</h4>
                      )}
                    </Fragment>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
  {
  }
};

Listings.propTypes = {
  getListings: PropTypes.func.isRequired,
  listing: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  listing: state.listing
});

export default connect(mapStateToProps, { getListings })(Listings);
