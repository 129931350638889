import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classes from './style.module.css';

const DropZone = ({ onChangeFiles, images }) => {
  const [files, setFiles] = useState([]);
  const [imageSrc, setImageSrc] = useState(images);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      onChangeFiles(acceptedFiles);
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  useEffect(() => {
    setImageSrc(images);
  }, [images]);

  const removeFile = (file, isUrl) => () => {
    if (isUrl) {
      const existImages = [...imageSrc];
      existImages.splice(existImages.indexOf(file), 1);
      setImageSrc(existImages);
      onChangeFiles([...existImages, ...files]);
    } else {
      const newFiles = [...files];
      newFiles.splice(newFiles.indexOf(file), 1);
      setFiles(newFiles);
      onChangeFiles(newFiles);
    }
  };

  const fileUrl = filename => `https://tidyly-dev.s3-ap-southeast-2.amazonaws.com/${filename}`;

  const preview = (files, isPreview = true) =>
    files.map(file => (
      <>
        <div className={classes.thumb} key={isPreview ? file.name : file}>
          <div className={classes.thumbInner}>
            <img
              src={isPreview ? file.preview : fileUrl(file)}
              className={classes.thumbImage}
              alt="img"
            />
            <div className={classes.thumbImageRemove}>
              <i className="fas fa-times" onClick={removeFile(file, !isPreview)} />
            </div>
          </div>
        </div>
      </>
    ));

  const thumbs = preview(files);
  const existingImages = preview(imageSrc, false);

  useEffect(
    () => () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <section>
      <div
        className="dropzone dropzone-area dz-clickable"
        id="dpz-remove-thumb"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <p className="dz-message">Drop files here or click to upload.</p>
      </div>
      <aside className={classes.thumbsContainer}>
        {existingImages}
        {thumbs}
      </aside>
    </section>
  );
};

export default DropZone;
