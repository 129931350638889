export const extractFromAddress = (components, type) => {
  return (
    components
      .filter(component => component.types.includes(type))
      .map(item => item.long_name)
      .pop() || null
  );
};

export const getParsedAddress = (address) => {
    const address1 = `${extractFromAddress(address, "premise") ||
      ""} ${extractFromAddress(address, "street_number") ||
      ""} ${extractFromAddress(address, "route") || ""}`.trim();
    const address2 = `${extractFromAddress(address, "neighborhood") ||
      ""} ${extractFromAddress(address, "sublocality_level_3") ||
      ""} ${extractFromAddress(address, "sublocality_level_2") ||
      ""} ${extractFromAddress(address, "sublocality_level_1") || ""}
    `.trim();
    const room = extractFromAddress(address, "room");
    const floor = extractFromAddress(address, "floor");
    const subpremise = extractFromAddress(address, "subpremise");
    const premise = extractFromAddress(address, "premise");
    const street_number = extractFromAddress(address, "street_number");
    const street_name = extractFromAddress(address, "route");
    const city = extractFromAddress(address, "administrative_area_level_2");
    const suburb = extractFromAddress(address, "locality");
    const state = extractFromAddress(
      address,
      "administrative_area_level_1"
    );
    const country = extractFromAddress(address, "country");
    const post_code = extractFromAddress(address, "postal_code");
    return {
      room,
      floor,
      subpremise,
      premise,
      street_number,
      street_name,
      address1,
      address2,
      suburb,
      city,
      state,
      country,
      post_code,
    };
  };