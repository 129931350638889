import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Landing from './components/layout/Landing';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import Alert from './components/layout/Alert';
import Dashboard from './components/dashboard/Dashboard';
// import CreateProfile from './components/profile-forms/CreateProfile';
import EditMyProfile from './components/profile-forms/EditMyProfile';
import EditProfile from './components/profile-forms/EditProfile';
import Listings from './components/listings/Listings';
import Reviews from './components/listings/ListingReviews';
import Profiles from './components/profiles/Profiles';
import PrivateRoute from './components/routing/PrivateRoute';
import ListingEdit from './components/listings/ListingEdit';
import Categories from './components/categories/Categories';
import SubCategories from './components/categories/SubCategories';
import ManageCategories from './components/categories/ManageCategory';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import './App.css';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Alert />
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/(login)" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route component={DashboardContainer} />
        </Switch>
      </Router>
    </Provider>
  );
};

// const PublicContainer = () => (
//   <Fragment>
//     <Route exact path="/login" component={Login} />
//   </Fragment>
// );

const DashboardContainer = () => (
  <Fragment>
    <Navbar />
    {/* // Local storage to be fixed!!!! — Temporary */}
    <div className={`app-content content ${localStorage.page}`}>
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow"></div>
      <div className="content-wrapper">
        <div className="content-header row"></div>
        <div className="content-body">
          <Switch>
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path="/listings" component={Listings} />
            <PrivateRoute exact path="/profiles" component={Profiles} />
            <PrivateRoute exact path="/categories" component={Categories} />
            <PrivateRoute exact path="/profileAdd" component={EditProfile} />
            <PrivateRoute exact path="/listingsAdd" component={ListingEdit} />
            <PrivateRoute exact path="/categoriesAdd" component={ManageCategories} />
            <PrivateRoute exact path="/subCategoriesAdd/:categoryId" component={ManageCategories} />
            <PrivateRoute exact path="/categories/:categoryId/subCategories/:id" component={ManageCategories} />
            <PrivateRoute exact path="/categories/:categoryId" component={ManageCategories} />
            <PrivateRoute exact path="/categories/:categoryId/subCategories" component={SubCategories} />
            <PrivateRoute exact path="/profileEdit/:id" component={EditProfile} />
            <PrivateRoute exact path="/listingEdit/:id" component={ListingEdit} />
            <PrivateRoute exact path="/listingEdit/:id/reviews" component={Reviews} />
            {/* <PrivateRoute
              exact
              path="/create-profile"
              component={CreateProfile}
            /> */}
            <PrivateRoute exact path="/edit-profile" component={EditMyProfile} />
          </Switch>
        </div>
      </div>
    </div>
  </Fragment>
);

export default App;
