import React, { Fragment, useEffect } from 'react';
import Spinner from '../layout/Spinner';
import CategoriesItem from './CategoriesItem';
import { Link, useLocation } from 'react-router-dom';
import {getCategories} from '../../actions/category';
import { connect } from 'react-redux';

const Categories = ({getCategories, category: { categories, loading }}) => {
    
    useEffect(()=>{
        getCategories();
    },[getCategories, loading]);

    const location = useLocation();
    const pathname = location.pathname;

    return (
        <Fragment>
            <div className="row" id="basic-table">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Categories</h4>
                            <Link to={`/categoriesAdd`}>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                >
                                    Add category
                                </button>
                            </Link>
                        </div>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <tr>
                                        <td colSpan="4">
                                            <Spinner />
                                        </td>
                                        </tr>
                                    ) : (
                                        <Fragment>
                                            {categories?.data?.data?.length > 0 ? 
                                                (categories?.data?.data.map(category => (
                                                        <Fragment key={category._id}>
                                                            <CategoriesItem 
                                                                category={category} 
                                                                subCategoriesItem={true}
                                                                pathname={pathname}
                                                            />
                                                        </Fragment>
                                                    ))
                                                ) : (
                                                    <h4>No categories found</h4>
                                                )
                                            }
                                        </Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    category: state.category
});
  
export default connect(mapStateToProps, { getCategories })(Categories);
